import Link from "next/link";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const becomeMemberPage = (): JSX.Element => (
  <Layout title="Hoe lid worden?">
    <div className="bg-secondary">
      <PageHeader title={"Hoe lid worden?"} />

      <main className="page">
        <section id="hoe-lid-worden">
          <h2>
            Ben je geïnteresseerd in bmx en wil je misschien aansluiten bij onze
            club?{" "}
          </h2>
          <h3>Dan is dit handige informatie voor jou:</h3>
          <ul>
            <li>
              Iedere vrijdag vanaf 11 maart tot eind oktober, is er een instapmogelijkheid voor nieuwe rijders. Je hoeft hiervoor niet op voorhand in te schrijven. We willen iedereen vragen om uiterlijk om 18u15 aanwezig te zijn, zeker wanneer er een fiets / helm gehuurd moet worden. Wat vroeger komen mag ook 😉 De les start om 18u30 en duurt tot 19u30.
              <br/>Meld je even aan in de kantine, daar krijg je nog meer info.
            </li>
            <li>
              De <strong>eerste drie keer is gratis</strong>, daarna neem je een
              recreantenvergunning van 28 euro. Deze vergunning doet tevens
              dienst als verzekering. Je bent hiermee overal en altijd verzekerd
              als je op je BMX-fiets zit, ook buiten de club.{" "}
            </li>
            <li>
              Daarnaast betaal je 110 euro lidgeld (vanaf augustus verminderd
              dit) per jaar voor het eerste lid van het gezin, een tweede, derde, ... lid betaalt nog 90 euro per jaar.{" "}
            </li>
            <li>
              Wat heb je nodig?
              <ul>
                <li>Kledij: lange broek, lange mouwen en handschoenen.</li>
                <li>Fiets en helm kan je steeds huren bij de club.</li>
              </ul>
            </li>
            <li>
              Het eerste jaar blijf je recreant. Nadien heb je de keuze om
              recreant te blijven of vergunninghouder te worden. Op het einde
              van het seizoen (oktober) zal er een uitgebreide briefing gegeven
              worden over de mogelijkheden. Ben je nu al nieuwsgierig? Kijk even
              op deze pagina.
            </li>
            <li>
              Ben je overtuigd en wil je aansluiten bij de club? Inschrijven kan
              via de knop onderdaan deze pagina.{" "}
            </li>
          </ul>
        </section>

        <section id="lid-worden">
          <h2>Ben je reeds lid, of heb je beslist lid te worden?</h2>
          <p>
            Je kan via deze link lid worden of je lidmaatschap vernieuwen. De
            betaling gebeurt digitaal.
          </p>
          <p className="text-center sm:text-left">
            <Link href="/inschrijven">
              <a className="btn btn-cta pl-5 pr-5">Inschrijven seizoen 2024</a>
            </Link>
          </p>
        </section>
      </main>
    </div>
  </Layout>
);

export default becomeMemberPage;
